<template lang="pug">
modal(size='sm' @close='$emit("close")')
  generic-form#FormTagsAdd(:endpoint='`/_/Projects/${projectId}/ProjectTags/EditForm/${productId}`' updateOnSubmitSuccess='AddTag,EditTag' @submitSuccess='$emit("close")')
    template(#default='{ config }')
      form-header
        span(v-html='$t("Edit Tags")')

      template(v-if='config.items && config.items.EditTags.options && config.items.EditTags.options.length > 0')
        div(class='bleed')
          form-select-list(name='EditTags' :multiple='true' class='mb-3')
            template(#actions='{ item }')
              btn(faded icon='custom-edit' :data-tooltip='$t("Edit Tag")' @click.stop.prevent='$utils.routeQueryAdd({ "form-project-tag-edit": { projectId, tagName: item.value } })')
        form-footer
          form-action(:primary='true' name='Update')
          template(#secondary)
            btn(secondary icon='plus' @click='$utils.routeQueryAdd({ "form-project-tag-create": projectId })') {{ $t('Add Tag') }}
      empty-state(v-else :centered='true')
        template(#message)
          span {{ $t('There are no tags in this project yet.') }}
        template(#buttons)
          btn(secondary icon='plus' @click='$utils.routeQueryAdd({ "form-project-tag-create": projectId })') {{ $t('Add Tag') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormProjectTagsAdd = defineComponent({
  inheritAttrs: false,
  props: {
    productId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
})
export default FormProjectTagsAdd
</script>
